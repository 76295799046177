import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
import LinkBtn from "../components/rows/LinkBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./home.css";
import { Button } from "bootstrap";

import wideBg from "./video/Comp_5_6.mov";

import { GetDomainName } from "../components/GetDomainName";

import { GetLoader, CallApi } from "../hooks/Services";

// import {
//   faGear,
//   faBoltLightning,
//   faStar,
// } from "@fortawesome/free-solid-svg-icons";

// const element = <FontAwesomeIcon icon={faCoffee} />;

export default function Home() {
  let navigate = useNavigate();

  const initialState = {
    urllink: "",
    email: "",
    name: "",
  };

  const [state, setState] = useState(initialState);

  const onChangeValue = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // const FakeReport = async (event) => {
  //   event.preventDefault();
  //   document.body.style.overflow = "hidden";
  //   setState({ ...state, showLoading: true });

  //   const getLoader = await GetLoader();

  //   if (getLoader)
  //     return navigate("/report", {
  //       state: { topi: "gfg" },
  //     });
  //   // return navigate("/report", {
  //   //   state: { topic: state.writingtTopic, respData: response },
  //   // });
  // };

  const GetReport = async (event) => {
    event.preventDefault();

    if (state.urllink !== "" && state.email !== "" && state.name !== "") {
      // document.body.style.overflow = "hidden";
      setState({ ...state, showLoading: true });

      const url = state.urllink.startsWith("http")
        ? state.urllink
        : "http://" + state.urllink;
      // const response = await CallApi("POST", "assess", pdata);
      const response = await CallApi("POST", "crawl", {
        // url: "http://" + state.urllink,
        url: url,
        email: state.email,
        name: state.name,
      });

      console.log("write", response);

      const getLoader = await GetLoader();

      if (getLoader) {
        // return navigate("/report", {
        //   state: { urllink: state.urllink, respData: response },
        // });

        // const link = GetDomainName(state.urllink);
        // const link = state.urllink;

        // const link = state.urllink.replace(/\./g, "_");
        const link = state.urllink.startsWith("http")
          ? GetDomainName(state.urllink)
          : state.urllink;
        // return navigate(`/report/${link}`, {
        //   state: { urllink: link, respData: response },
        // });
        return navigate(`/report`, {
          state: { urllink: link, respData: response },
        });
      }
    }
  };

  return (
    <>
      <div
        id="backdrop"
        className={state.showLoading ? "visible" : "invisible"}
      >
        <div className="text-center loading">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6rem", height: "6rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      {/* <video playsinline autoplay muted loop poster="polina.jpg" id="bgvid"> */}
      {/* <video playsInline autoPlay muted loop id="bgvid"> */}
      {/* <video controls autoPlay loop muted> */}
      <video autoPlay muted>
        {/* <video> */}
        {/* <source src={wideBg} type="video/mp4" /> */}
        <source src="movie/Comp_final.mp4" type="video/mp4" />
        {/* <source src="movie/Comp_5_6.mov" type="video/mov" /> */}
        {/* <source src={wideBg} type="video/mp4" /> */}
      </video>
      <Section
        className="container-fluid viewport-header"
        style={{ height: "100%" }}
      >
        <div className="container text-center">
          <Row className="makecenter">
            <Col className="text-center">
              <h1 className="fw-bold" style={{ fontSize: 100 }}>
                WA!site
              </h1>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-center text-start">
              {/* <!-- <h2 style="padding-left: 30%"> --> */}
              <div className="neumorphism_title">
                <h2 className="itemwidth" style={{ fontSize: 50 }}>
                  Check your <br />
                  <span className="fw-bold">
                    <i>Website Accessibility</i>
                  </span>{" "}
                  now
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            {/* <!-- <div className="col" style="padding-left: 30%"> -->
          <!-- <div className="col"></div> --> */}

            <Col className="d-flex justify-content-center mb-5">
              {/* <form className="neumorphism" action="./report" method="post"> */}
              <div className="neumorphism">
                {/* <!-- <input type="text" id="fname" name="fname" /> --> */}

                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={state.name}
                  onChange={onChangeValue}
                />
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Phone / Email"
                  value={state.email}
                  onChange={onChangeValue}
                />
              </div>
            </Col>
            <Col className="d-flex justify-content-center">
              {/* <form className="neumorphism" action="./report" method="post"> */}
              <div className="neumorphism">
                {/* <!-- <input type="text" id="fname" name="fname" /> --> */}

                <input
                  type="text"
                  className="form-control"
                  id="urllink"
                  name="urllink"
                  placeholder="Website Url"
                  value={state.urllink}
                  onChange={onChangeValue}
                />
                {/* <button type="submit" className="moreBtn "  onClick={GetReport}> */}
                <button
                  type="submit"
                  className={`moreBtn ${
                    state.showLoading ? "invisible" : "visible"
                  }`}
                  onClick={GetReport}
                >
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Section>
    </>
  );
}
