import { useOutlet } from "react-router-dom";
import { AppBar } from "./AppBar";
import Header from "../rows/Header";
import Main from "../rows/Main";
import Footer from "../rows/Footer";
import Section from "../rows/Section";
import Row from "../rows/Row";
import Col from "../rows/Col";

export default function MainLayout() {
  const outlet = useOutlet();

  return (
    <>
      <Header></Header>
      {/* <AppBar
        pages={[
          { label: "Home", path: "/" },
          { label: "Assessor", path: "/Assessor" },
          { label: "Pricing", path: "/pricing" },
          { label: "Contact Us", path: "/contactus" },
          { label: "Sign in", path: "/signin" },
        ]}
      /> */}
      <Main className="container-fluid main-bg">{outlet}</Main>
      <Footer></Footer>
    </>
  );
}
