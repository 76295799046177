export default function spbar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="910"
      height="18"
      viewBox="0 0 910 18"
      fill="none"
    >
      <g filter="url(#filter0_ddiiii_112_1853)">
        <path d="M5 9H905" stroke="black" strokeWidth="7" />
      </g>
      <defs>
        <filter
          id="filter0_ddiiii_112_1853"
          x="-44"
          y="-43.5"
          width="998"
          height="105"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.878431 0 0 0 0 0.894118 0 0 0 0 0.905882 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_112_1853"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.972549 0 0 0 0 0.988235 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_112_1853"
            result="effect2_dropShadow_112_1853"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_112_1853"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="49" dy="49" />
          <feGaussianBlur stdDeviation="61.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.878431 0 0 0 0 0.894118 0 0 0 0 0.905882 0 0 0 0.9 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect3_innerShadow_112_1853"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-49" dy="-49" />
          <feGaussianBlur stdDeviation="49" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.972549 0 0 0 0 0.988235 0 0 0 0 1 0 0 0 0.9 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_112_1853"
            result="effect4_innerShadow_112_1853"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="49" dy="-49" />
          <feGaussianBlur stdDeviation="49" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.878431 0 0 0 0 0.894118 0 0 0 0 0.905882 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_innerShadow_112_1853"
            result="effect5_innerShadow_112_1853"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-49" dy="49" />
          <feGaussianBlur stdDeviation="49" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.878431 0 0 0 0 0.894118 0 0 0 0 0.905882 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect5_innerShadow_112_1853"
            result="effect6_innerShadow_112_1853"
          />
        </filter>
      </defs>
    </svg>
  );
}
