import Home from "./pages/Home";
import Report from "./pages/Report";
import ClientReport from "./pages/ClientReport";
import Printpage from "./pages/Printpage";
// import ThermalPrinter from "./components/printer/ThermalPrinter";
// import PrintTest from "./pages/PrintTest";
// import PrintComponent from "./components/printer/PrintComponent";
// import Card from "./pages/Card";

import MainLayout from "./components/base/MainLayout";


export default function App() {

  const router = [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { index: true, element: <Home />, },
        { path: "/report", element: <Report />, },
        // { path: "/report/:link", element: <Report />, },
        // { path: "/print", element: <ThermalPrinter />, },

        // { path: "/printtest", element: <PrintTest />, },

        // { path: "/print2", element: <PrintComponent /> },
        // { path: "/card", element: <Card /> }


        { path: "/c_report/:link/:wcag244/:wcag131/:wcag122/:wcag111/:wcag332/:wcag135/:wcag144/:wcag143/:wcag253/:wcag412/:wcag241/:wcag242/:wcag311", element: <ClientReport />, },
        // ", element: <ClientReport />, },

      ],
    },
    { path: "/printpage/:link/:wcag244/:wcag131/:wcag122/:wcag111/:wcag332/:wcag135/:wcag144/:wcag143/:wcag253/:wcag412/:wcag241/:wcag242/:wcag311", element: <Printpage />, },
  ];

  return router
}

// const reportorder = [
//   "wcag244",
//   "wcag131",

//   "wcag122",
//   "wcag111",

//   "wcag332",
//   "wcag135",
//   "wcag144",

//   "wcag143",

//   "wcag253",
//   "wcag412",
//   "wcag241",
//   "wcag242",
//   "wcag311",
// ];