import axios from "axios";

export async function GetLoader() {
  console.log("start loading");
  await FakeNetwork();

  console.log("End loading");
  return true;
}

async function FakeNetwork() {
  return new Promise((res) => {
    setTimeout(res, Math.random() * 2400);
  });
}

// const domainUrl =
//   window.location.host == "localhost:3000"
//     ? "http://" + window.location.hostname + ":7500"
//     : "http://192.168.1.198/api/v1/";

const domainUrl =
  "https://asia-east2-justin-fuck-all.cloudfunctions.net/wa-server";
// "https://wa-server-vxkir2gkeq-df.a.run.app";
// "https://asia-east2-justin-fuck-all.cloudfunctions.net/wa-server/crawl";

// https://storage.googleapis.com/wa_server/crawler-server-g.zip
// Define the base URL
const Axios = axios.create({
  // baseURL: "http://192.168.88.82:7500",
  // baseURL: "http://192.168.0.129:7500",
  // baseURL: "http://localhost:7500",
  baseURL: domainUrl,
});

export async function CallApi(type, url, query = []) {
  // console.log(login);
  try {
    let method = type.toString().toLowerCase();
    let endpoint = url.toString();
    let data = query;

    let response = await Axios[method](
      endpoint,
      method === "get" ? { params: data } : data
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
}

export default CallApi;
