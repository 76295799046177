// const url = require('url');
// const dns = require('dns');
import url from 'url';


export function GetDomainName(Domainurl) {

    // // 要获取域名的 URL
    // const urlString = Domainurl;

    // // 解析 URL
    // const parsedUrl = url.parse(urlString);

    // // 提取域名
    // const domainName = parsedUrl.hostname;

    // // 获取域名的 IP 地址
    // dns.resolve4(domainName, (err, addresses) => {
    //     if (err) {
    //         console.error(`无法获取域名 ${domainName} 的 IP 地址: ${err.message}`);
    //         return err.message
    //     } else {
    //         console.log(`域名 ${domainName} 的 IP 地址是: ${addresses.join(', ')}`);
    //         return domainName
    //     }
    // });

    // 要获取域名的 URL
    const urlString = Domainurl;

    // 解析 URL
    const parsedUrl = url.parse(urlString);

    // 提取域名
    const domainName = parsedUrl.hostname;

    // console.log(`URL "${urlString}" 的域名是: ${domainName}`);

    return domainName
}

