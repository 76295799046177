import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
import LinkBtn from "../components/rows/LinkBtn";
import InputBtn from "../components/inputform/InputBtn";
import DemoBtn from "../components/inputform/DemoBtn";
import Textareas from "../components/inputform/Textareas";
import Separator from "../components/rows/Separator";
// import RadarChart from "../components/chartjs/RadarChart";
// import RowReactTable from "../components/reactTable/RowReactTable";
import Btn from "../components/inputform/Btn";
import Spbar from "../components/spbar";
import Collapse from "../components/Collapse";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./report.css";
import "./style_report.scss";

import ThermalPrinter from "../components/printer/ThermalPrinter";

// import {
//   faGear,
//   faBoltLightning,
//   faStar,
// } from "@fortawesome/free-solid-svg-icons";

// const element = <FontAwesomeIcon icon={faCoffee} />;

export default function Report() {
  const location = useLocation();
  const urllink = location.state.urllink;
  const respData = location.state.respData;
  console.log("urllink", urllink);
  console.log("respData", respData);

  // let { link } = useParams();
  // console.log("link", link);

  const [selectedAnchor, setSelectedAnchor] = useState(null);

  const [percent, setPercent] = useState(null);

  // const markStructure =
  //   (respData.wcag244 +
  //     respData.wcag131 +
  //     respData.wcag132 +
  //     respData.wcag246 +
  //     respData.wcag245) /
  //   5;

  // const markImage =
  //   (respData.wcag122 + respData.wcag111 + respData.wcag122) / 3;

  // const markText = (respData.wcag135 + respData.wcag135 + respData.wcag144) / 3;
  // const markColor = (respData.wcag1411 + respData.wcag143) / 2;

  // const markCode =
  //   (respData.wcag253 +
  //     respData.wcag412 +
  //     respData.wcag241 +
  //     respData.wcag242 +
  //     respData.wcag311 +
  //     respData.wcag246) /
  //   6;

  const markStructureLabel = [
    "wcag244",
    "wcag131",
    "wcag132",
    "wcag246",
    "wcag245",
  ];
  // const markImageLabel = ["wcag122", "wcag111", "wcag122"];
  const markImageLabel = ["wcag122", "wcag111"];

  const markTextLabel = ["wcag332", "wcag135", "wcag144"];
  const markColorLabel = ["wcag1411", "wcag143"];

  const markCodeLabel = [
    "wcag253",
    "wcag412",
    "wcag241",
    "wcag242",
    "wcag311",
    "wcag246",
  ];

  const combinedArray = [
    ...markStructureLabel,
    ...markImageLabel,
    ...markTextLabel,
    ...markColorLabel,
    ...markCodeLabel,
  ];

  const markAVG = (jsonData, label) => {
    const valuesToAverage = label
      .map((key) => jsonData[key])
      .filter((value) => value !== undefined && value !== 0);

    // 计算平均值
    const sum = valuesToAverage.reduce(
      (acc, currentValue) => acc + currentValue,
      0
    );
    const average = sum / valuesToAverage.length;

    return average;
  };

  const markStructure = markAVG(respData, markStructureLabel);
  const markImage = markAVG(respData, markImageLabel);
  const markText = markAVG(respData, markTextLabel);
  const markColor = markAVG(respData, markColorLabel);
  const markCode = markAVG(respData, markCodeLabel);

  console.log(markStructure, markImage, markText, markColor, markCode);
  // wcag244: 64,
  // Structure: 17,
  // wcag132: 49,
  // wcag246: 72,
  // wcag245: 98,
  // wcag122: 100,
  // wcag135: 71,
  // wcag144: 59,
  // wcag1411: 51,
  // wcag143: 20,
  // wcag412: 80,
  // ImageLinkAlt: 3,
  // wcag111: 83,
  // wcag241: 62,
  // wcag122: 74,
  // wcag242: 19,
  // wcag311: 6,
  // wcag135: 77,
  // wcag253: 51

  // const GetReport = () => {

  //   let ePosDev = new window.epson.ePOSDevice();
  //   ePosDevice.current = ePosDev;

  //   ePosDev.connect(printerIPAddress, printerPort, (data) => {
  //     if (data === "OK") {
  //       ePosDev.createDevice(
  //         "local_printer",
  //         ePosDev.DEVICE_TYPE_PRINTER,
  //         { crypto: true, buffer: false },
  //         (devobj, retcode) => {
  //           if (retcode === "OK") {
  //             printer.current = devobj;
  //             setConnectionStatus(STATUS_CONNECTED);
  //           } else {
  //             throw retcode;
  //           }
  //         }
  //       );
  //     } else {
  //       throw data;
  //     }
  //   });
  // };

  // useEffect(() => {
  //   let ePosDev = new window.epson.ePOSDevice();
  //   ePosDevice.current = ePosDev;

  //   ePosDev.connect(printerIPAddress, printerPort, (data) => {
  //     if (data === "OK") {
  //       ePosDev.createDevice(
  //         "local_printer",
  //         ePosDev.DEVICE_TYPE_PRINTER,
  //         { crypto: true, buffer: false },
  //         (devobj, retcode) => {
  //           if (retcode === "OK") {
  //             printer.current = devobj;
  //             setConnectionStatus(STATUS_CONNECTED);
  //           } else {
  //             throw retcode;
  //           }
  //         }
  //       );
  //     } else {
  //       throw data;
  //     }
  //   });
  // });

  // const TotalMark = (
  //   (markStructure + markImage + markText + markColor + markCode) /
  //   5
  // ).toFixed(0);

  const TotalMark = markAVG(respData, combinedArray).toFixed(0);
  console.log(markAVG(respData, combinedArray));

  // const TotalMark = 70;
  useEffect(() => {
    // const circle = document.getElementById("circle");
    let change = 565.49 - (565.49 * TotalMark) / 100;
    // circle.style.strokeDashoffset = change;
    setPercent(change);
  }, []);

  const svgStyle = {
    // strokeDashoffset: `${percent}%`, // 使用模板字符串将百分比值添加到样式中
    strokeDashoffset: `${percent}`, // 使用模板字符串将百分比值添加到样式中
  };

  const circlePercent = (num) => {
    const circle = document.getElementById("circle");
    let change = 565.49 - (565.49 * num) / 100;
    // circle.style.strokeDashoffset = change;
    setPercent(change);
  };
  // circlePercent(TotalMark)

  // document.addEventListener("DOMContentLoaded", function () {
  //   // 在這裡執行您的 JavaScript 代碼
  //   // var circle = document.querySelector(".circle");
  //   const circle = document.getElementById("circle");

  //   // 確保 circle 不是 null 才訪問其屬性
  //   if (circle) {
  //     // circle.style.strokeDashoffset = "50";
  //     circlePercent(TotalMark);
  //   }
  // });

  const GetSectionMark = ({ mark }) => {
    return (
      <p style={{ fontSize: 30 }}>
        <span style={{ fontSize: 40 }}>{mark.toFixed(0)}</span>
        /100
      </p>
    );
  };

  return (
    <>
      {/* <button onClick={ThermalPrinter}>Print</button> */}
      <Section className="container-fluid pt-0">
        <img
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            margin: "0 auto",
          }}
          src="/img/BG_pattern.png"
        />
      </Section>
      <Section
        className="container-fluid text-center pt-5"
        style={{ background: "ecf0f3" }}
      >
        <Row className="mt-5 pt-3">
          <Col>
            <p className="scantext caafont">Scan completed for {urllink}</p>
            <p className="scantext2">
              The score of the website accessibility is...
            </p>
          </Col>
        </Row>
        {/* <!-- chart start--> */}
        <Row>
          {/* <!-- <div className="card"> --> */}
          <div className="card__percent">
            <svg className="chart">
              <defs>
                <radialGradient
                  id="gradient"
                  cx="50%"
                  cy="50%"
                  r="60%"
                  fx="50%"
                  fy="50%"
                >
                  <stop offset="30%" stopColor="var(--primary-dark)" />
                  <stop offset="100%" stopColor="var(--primary-light)" />
                </radialGradient>
              </defs>
              <circle
                cx="90"
                cy="90"
                r="90"
                stroke="url(#gradient)"
                id="circle"
                style={svgStyle}
              ></circle>
            </svg>
            <div className="circle"></div>
            <div className="circle circle__medium"></div>
            <div className="circle circle__small"></div>
            <div className="card__number robotofont">{TotalMark}</div>
          </div>
          {/* <!-- </div> --> */}
          {/* {circlePercent(TotalMark)} */}
        </Row>
        {/* //   <!-- chart end --> */}

        <div className="container" style={{ color: "757f8a" }}>
          {/* <!-- email start --> */}

          {/* <!-- <input type="text" id="fname" name="fname" /> --> */}
          {/* <Row>
            <Col>
              <p className="score">
                Your score is 60% better than other website!
              </p>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col className="d-flex justify-content-center">
              <form className="neumorphism" action="./report.html">

                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput"
                  placeholder="Example input placeholder"
                />
                <button type="submit" className="moreBtn">
                  <img style={{ width: "100%" }} src="/img/sendbtn.png" />
                </button>
              </form>
            </Col>
          </Row> */}

          {/* <!-- <i className="fa fa-angle-right"></i> --> */}
          {/* <!-- email end --> */}

          {/* Structure */}
          <Row className="text-start caafont">
            <Col>
              <h2 className="fs-1 fw-bold">Structure</h2>
            </Col>
            <Col className="text-end">
              <GetSectionMark mark={markStructure} />
            </Col>
          </Row>
          <section className="p-4 mb-5">
            <Row className="spbar">
              <Spbar />
            </Row>
            {respData.wcag244 > 0 && (
              <Collapse
                level="A"
                head="Use unique and descriptive link labels"
                desc={`Terms like “click here” and “read more” don’t provide any context. Screen readers can give visitors an overview of all the links on a page. 

                Whenever adding or using a button, link or a label, make sure the copy tells readers what will happen on click.
                
                WCAG reference: 2.4.4 Link Purpose (In Context)`}
                mark={respData.wcag244}
              />
            )}
            {respData.wcag131 > 0 && (
              <Collapse
                level="A"
                head="Ensure Coding has appropriate structure and relationships"
                desc={`Tabular data is presented using proper HTML markup (<table>, <tr>, <th>, and <td> elements)

                Lists of items are presented using proper HTML markup (<ul>, <ol>, and <li> elements) 

                Headings are presented using proper HTML markup (<h1> through <h6> elements) 
                
                Form fields have properly associated <label> elements; 
                
                Sets of radio buttons or checkboxes have a <fieldset> element to group them together, and a <legend> element to provide a label for the group
                
                WCAG reference: 1.3.1 Info and Relationships`}
                mark={respData.wcag131}
              />
            )}
            {/* <Collapse
              level="A"
              head="Layout is simple, straightforward, and consistent"
              desc={``}
              mark={respData.wcag132}
            /> */}
            {/* <Collapse
              level="AA"
              head="Use clear, descriptive, sequential, logical heading order for Content"
              desc={``}
              mark={respData.wcag246}
            /> */}
            {/* <Collapse
              level="AA"
              head="More than one way is available to locate a Web page"
              desc={``}
              mark={respData.wcag245}
            /> */}
          </section>

          {/* Image & Audio */}
          <Row className="text-start caafont">
            <Col>
              <h2 className="fs-1 fw-bold">Image & Audio</h2>
            </Col>
            <Col className="text-end">
              <GetSectionMark mark={markImage} />
            </Col>
          </Row>
          <section className="p-4 mb-5">
            <Row className="spbar">
              <Spbar />
            </Row>
            {respData.wcag122 > 0 && (
              <Collapse
                level="A"
                head="Include captions in videos"
                desc={`Captions allow Deaf or hard of hearing people to access audio content. 

                Some video services like YouTube use an algorithm to automatically generate captions. This is a fast but often ineffective method, as the captions are often incorrect.
                
                WCAG reference: 1.2.2 Captions (Prerecorded)`}
                mark={respData.wcag122}
              />
            )}
            {respData.wcag111 > 0 && (
              <Collapse
                level="A"
                head="Include alt text for every meaningful images"
                desc={`Alt text describes images to visitors who are unable to see them. 

                This includes screen readers and browsers that block images, but it also includes users who are sight-impaired or otherwise unable to visually identify an image.
                
                WCAG reference: 1.1.1 Non-text Content`}
                mark={respData.wcag111}
              />
            )}
            {/* <Collapse
              level="AA"
              head="Include audio descriptions"
              desc={``}
              mark={respData.wcag122} D
            /> */}
          </section>

          {/* Text */}
          <Row className="text-start caafont">
            <Col>
              <h2 className="fs-1 fw-bold">Text</h2>
            </Col>
            <Col className="text-end">
              <GetSectionMark mark={markText} />
            </Col>
          </Row>
          <section className="p-4 mb-5">
            <Row className="spbar">
              <Spbar />
            </Row>
            {respData.wcag332 > 0 && (
              <Collapse
                level="A"
                head="Input field should instruct user to enter specific format"
                desc={`Instructions are provided for fields that require data to be entered in a specific format 

                Instructions are properly associated with the relevant form field
                
                WCAG reference: 3.3.2 Labels or Instructions`}
                mark={respData.wcag332}
              />
            )}
            {respData.wcag135 > 0 && (
              <Collapse
                level="AA"
                head="Input field should indicate the type of info required"
                desc={`Or other semantic that identifies the purpose of the input. 

                Which is easier for Assistive control or browsers to fill in user information by auto-completed. 
                
                E.g. In a form, the "phone number" box can only input numbers, e-mail is required to input "@"
                
                WCAG reference: 1.3.5 Identify Input Purpose`}
                mark={respData.wcag135}
              />
            )}
            {respData.wcag144 > 0 && (
              <Collapse
                level="AA"
                head="Ensure text can be resized without loss of content or functionality"
                desc={`It must be possible for people to increase the size of text by up to 200%. This ensures that partially sighted people can comfortably read content.

                WCAG reference: 1.4.4 Resize text`}
                mark={respData.wcag144}
              />
            )}
          </section>

          {/* Color */}
          <Row className="text-start caafont">
            <Col>
              <h2 className="fs-1 fw-bold">Color</h2>
            </Col>
            <Col className="text-end">
              <GetSectionMark mark={markColor} />
            </Col>
          </Row>
          <section className="p-4 mb-5">
            <Row className="spbar">
              <Spbar />
            </Row>

            {/* <Collapse
              level="AA"
              head="Check buttons or inputs and controls have a ratio of 3:1 or higher"
              desc={``}
              mark={respData.wcag1411}
            /> */}
            {respData.wcag143 > 0 && (
              <Collapse
                level="AA"
                head="Check the contrast for all borders icons & texts"
                desc={`Level AA compliance requires a contrast ratio of: 
                • 3:1 for large text (>18pt normal, >14pt bold) 
                • 4.5:1 for body text (<18pt normal, <14pt bold)
                
                WCAG reference: 1.4.3 Contrast (Minimum)`}
                mark={respData.wcag143}
              />
            )}
          </section>

          {/* Code */}
          <Row className="text-start caafont">
            <Col>
              <h2 className="fs-1 fw-bold">Code</h2>
            </Col>
            <Col className="text-end">
              <GetSectionMark mark={markCode} />
            </Col>
          </Row>
          <section className="p-4 mb-5">
            <Row className="spbar">
              <Spbar />
            </Row>
            {respData.wcag253 > 0 && (
              <Collapse
                level="A"
                head="Accessible name start with visible label and matches"
                desc={`The accessible name of a “Start here” button begins with the same text as the visible label. And the accessible name and visible label of a control match.

                WCAG reference: 2.5.3 Label in Name`}
                mark={respData.wcag253}
              />
            )}
            {respData.wcag412 > 0 && (
              <Collapse
                level="A"
                head="Use correct elements to indicate important content regions"
                desc={`An <a> or <button> element contains no text content, and so has no accessible name; 

                A form field has no associated <label> element, and so has no accessible name; 
                
                A set of tabs is created using <ul>, <li>, and <a> elements, but ARIA has not been used to provide the tablist, tab, and tabpanel roles; 
                
                An <a> element has been used as the basis for a button, but the ARIA button role has not been applied; 
                
                A button is used as the trigger to disclose content, but the aria-expanded attribute has not been used to communicate the disclosure component’s current state; 
                
                A <div> or <span> has been used as the basis for an interactive component, but ARIA has not been used to identify the purpose of the component (or its constituent parts).
                
                WCAG reference: 4.1.2 Name, Role, Value`}
                mark={respData.wcag412}
              />
            )}
            {respData.wcag241 > 0 && (
              <Collapse
                level="A"
                head="Include a 'skip to main' link"
                desc={`Keyboard users use the “Tab” key to navigate from link to link on a web page. 

                Without a “Skip to main” link (sometimes referred to as a “skip navigation link”), users are forced to tab through every link in a navigation bar before getting to the main content.
                
                WCAG reference: 2.4.1 Bypass Blocks`}
                mark={respData.wcag241}
              />
            )}
            {respData.wcag242 > 0 && (
              <Collapse
                level="A"
                head="Provide a unique <title> for each page"
                desc={`The title element, contained in the document's head element, is often the first piece of information announced by assistive technology. 

                This helps tell people what page or view they are going to start navigating.
                
                WCAG reference: 2.4.2 Page Titled`}
                mark={respData.wcag242}
              />
            )}
            {respData.wcag311 > 0 && (
              <Collapse
                level="A"
                head="Set your site's language code"
                desc={`Setting the language on a webpage allows more people to access your content. 

                The language is identified using the lang attribute of the <span class="nt"><html</span> element.
                
                WCAG reference: 3.1.1 Language of Page`}
                mark={respData.wcag311}
              />
            )}
            {/* <Collapse
              level="A"
              head="Ensure coding has appropriate structure and relationships"
              desc={``}
              mark={respData.wcag246}
            /> */}
          </section>
        </div>
      </Section>
    </>
  );
}
