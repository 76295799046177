import Section from "./rows/Section";
import Row from "./rows/Row";
import Col from "./rows/Col";

export default function Collapse({ level, head, desc, mark }) {
  function RankingColor(mark) {
    if (mark >= 80) {
      return "greenMark";
    } else if (mark >= 60) {
      return "yellowMark";
    } else {
      return "redMark";
    }
  }

  return (
    <Row className="pb-5">
      <Col>
        <details>
          <summary>
            <Row className="text-start rp-row font2">
              <Col className="col-1">
                <p className="p-bg">{level}</p>
              </Col>
              <Col>
                {/* <p>{head}</p> */}
                {head}
              </Col>
              <Col className="col-1">
                {/* <div className="status-dot"></div> */}
                <label className={`${RankingColor(mark)}`}></label>
                {/* <!-- <p>{{resp.Image_link}}</p> --> */}
              </Col>
            </Row>
          </summary>
          <p className="text-start fs-5">
            <span style={{ whiteSpace: "pre-line" }}>{desc}</span>
          </p>
        </details>
      </Col>
    </Row>
  );
}

// <details>
//   <summary>Epcot Center</summary>
//   <p>
//     Epcot is a theme park at Walt Disney World Resort featuring exciting
//     attractions, international pavilions, award-winning fireworks and seasonal
//     special events.
//   </p>
// </details>;
